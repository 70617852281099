import { environment } from '@environments/environment';
import { NotificationApiEndpoints } from '@config/apis/notification/notification-api-endpoints';
import { createApiConfiguration } from '@fagforbundet/ngx-components';

export const notificationApiConfig = createApiConfiguration<
  typeof NotificationApiEndpoints
>({
  baseUrl: environment.apis.notification.baseUrl,
  endpoints: NotificationApiEndpoints,
});
